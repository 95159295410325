import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDocumentEditorMode, LazyDocumentIsTemplate, LazyDocumentUpdatingState, LazyDocumentVersion, LazyPartialEditorHeader, LazyBnslAccountLogo, LazyBnslAvatar, LazyBnslBtn, LazyBnslBtnTypes, LazyBnslCalendar, LazyBnslCalendarLocales, LazyBnslCalendarUseSetDateLanguage, LazyBnslFooterNavigationButtons, LazyBnslIcon, LazyBnslInputNumber, LazyBnslInputNumberEnumCurrencyCodes, LazyBnslInputNumberUseSetInputNumberLanguage, LazyBnslInputPhone, LazyBnslInputPhoneEnumPhoneCodes, LazyBnslLoading, LazyBnslLoadingSpinner, LazyBnslLogo, LazyBnslMenuDropdownMenu, LazyBnslModal, LazyBnslPoweredBy, LazyBnslProgressBar, LazyBnslTag, LazyBnslUserAvatar } from '#components'
const lazyGlobalComponents = [
  ["DocumentEditorMode", LazyDocumentEditorMode],
["DocumentIsTemplate", LazyDocumentIsTemplate],
["DocumentUpdatingState", LazyDocumentUpdatingState],
["DocumentVersion", LazyDocumentVersion],
["PartialEditorHeader", LazyPartialEditorHeader],
["BnslAccountLogo", LazyBnslAccountLogo],
["BnslAvatar", LazyBnslAvatar],
["BnslBtn", LazyBnslBtn],
["BnslBtnTypes", LazyBnslBtnTypes],
["BnslCalendar", LazyBnslCalendar],
["BnslCalendarLocales", LazyBnslCalendarLocales],
["BnslCalendarUseSetDateLanguage", LazyBnslCalendarUseSetDateLanguage],
["BnslFooterNavigationButtons", LazyBnslFooterNavigationButtons],
["BnslIcon", LazyBnslIcon],
["BnslInputNumber", LazyBnslInputNumber],
["BnslInputNumberEnumCurrencyCodes", LazyBnslInputNumberEnumCurrencyCodes],
["BnslInputNumberUseSetInputNumberLanguage", LazyBnslInputNumberUseSetInputNumberLanguage],
["BnslInputPhone", LazyBnslInputPhone],
["BnslInputPhoneEnumPhoneCodes", LazyBnslInputPhoneEnumPhoneCodes],
["BnslLoading", LazyBnslLoading],
["BnslLoadingSpinner", LazyBnslLoadingSpinner],
["BnslLogo", LazyBnslLogo],
["BnslMenuDropdownMenu", LazyBnslMenuDropdownMenu],
["BnslModal", LazyBnslModal],
["BnslPoweredBy", LazyBnslPoweredBy],
["BnslProgressBar", LazyBnslProgressBar],
["BnslTag", LazyBnslTag],
["BnslUserAvatar", LazyBnslUserAvatar],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
