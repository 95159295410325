import mitt, { Emitter } from 'mitt';

type Events = {
  [key: string]: any;
};

export default defineNuxtPlugin((_app) => {
  // @ts-ignore
  const emitter: Emitter<Events> = mitt();

  return {
    provide: {
      event: emitter.emit,
      listen: emitter.on,
    },
  };
});
