import { Config } from './config';
import { createApolloClient } from './apollo';
import { createRestClient } from './rest';
export const initSdk = (options) => {
    const config = new Config(options);
    return {
        config,
        apolloClient: createApolloClient(config),
        restClient: createRestClient(config),
    };
};
export * from './rest';
export * from './apollo';
export * from './utils/graphql';
