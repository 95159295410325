export default {};
export const calendarLocales = {
  dayNames: {
    en: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    es: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
  },
  dayNamesShort: {
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    es: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  },
  dayNamesMin: {
    en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    es: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
  },
  monthNames: {
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    es: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
  },
  monthNamesShort: {
    en: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    es: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
  },
  chooseYear: {
    en: 'Choose Year',
    es: 'Elegir año',
  },
  chooseMonth: {
    en: 'Choose Month',
    es: 'Elegir mes',
  },
  chooseDate: {
    en: 'Choose Date',
    es: 'Elegir fecha',
  },
  prevDecade: {
    en: 'Previous Decade',
    es: 'Década anterior',
  },
  nextDecade: {
    en: 'Next Decade',
    es: 'Próxima década',
  },
  prevYear: {
    en: 'Previous Year',
    es: 'Año anterior',
  },
  nextYear: {
    en: 'Next Year',
    es: 'Próximo año',
  },
  prevMonth: {
    en: 'Previous Month',
    es: 'Mes anterior',
  },
  nextMonth: {
    en: 'Next Month',
    es: 'Próximo mes',
  },
  prevHour: {
    en: 'Previous Hour',
    es: 'Hora anterior',
  },
  nextHour: {
    en: 'Next Hour',
    es: 'Próxima hora',
  },
  today: {
    en: 'Today',
    es: 'Hoy',
  },
  firstDayOfWeek: {
    en: 0,
    es: 1,
  },
  clear: {
    en: 'Clear',
    es: 'Limpiar',
  },
};
