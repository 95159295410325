<template>
  <Html :lang="locale">
    <Body class="antialiased duration-300 transition-colors">
      <NuxtLayout>
        <NuxtLoadingIndicator :height="5" :duration="3000" :throttle="400" />
        <NuxtPage />
      </NuxtLayout>
    </Body>
  </Html>
</template>

<script setup lang="ts">
const locale = useState<string>('locale.setting');

const app = useAppConfig();

useHead({
  title: app?.name as string,
  titleTemplate: '%s Flow',
  meta: [{ name: 'viewport', content: 'width=device-width, initial-scale=1' }],
  link: [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }],
});
</script>

<style lang="postcss">
html,
body {
  @apply w-full h-full;
}

div#__nuxt,
#__layout,
#__layout > div,
#app {
  @apply min-h-full;
}
</style>
```
