import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { AccountsApi, AuthApi, UsersApi, TemplatesApi, IntegrationsApi, } from './generated/rest';
import { requestInterceptors, responseInterceptors, responseErrorInterceptors, } from './utils';
const cache = setupCache({
    maxAge: 1 * 60 * 1000,
    exclude: {
        methods: ['put', 'patch', 'post', 'delete'],
    },
});
export const createRestClient = (config) => {
    const axiosInstance = axios.create({
        withCredentials: true,
        adapter: cache.adapter,
    });
    axiosInstance.interceptors.request.use(requestInterceptors(config));
    axiosInstance.interceptors.response.use(responseInterceptors, responseErrorInterceptors({
    // 401: async (error: AxiosError<any, any>) => {
    //   if (await store.getters['user/isLoggedIn']) {
    //     await store.dispatch('user/logout');
    //     router.push({ name: 'login' });
    //   }
    //   return error;
    // },
    }));
    const api = {
        config,
        auth: new AuthApi(undefined, config.basePath, axiosInstance),
        account: new AccountsApi(undefined, config.basePath, axiosInstance),
        user: new UsersApi(undefined, config.basePath, axiosInstance),
        template: new TemplatesApi(undefined, config.basePath, axiosInstance),
        integration: new IntegrationsApi(undefined, config.basePath, axiosInstance),
        logout: async () => {
            await api.auth.logout();
            await config.destroyAccount();
            await config.destroyLang();
        },
    };
    return { api, axiosInstance };
};
export * from './generated/rest';
