import Localforage from 'localforage';
export const ACCOUNT = 'bounsel-account';
export const LANG = 'lang';
export class Config {
    basePath;
    baseGraphqlPath;
    baseGraphqlSocketPath;
    accountKey = ACCOUNT;
    langKey = LANG;
    ssrMode = false;
    constructor({ apiUrl, apiGraphqlUrl, apiGraphqlSocketUrl, options, }) {
        this.basePath = (apiUrl || '').replace(/\/+$/, '');
        this.baseGraphqlPath = (apiGraphqlUrl || '').replace(/\/+$/, '');
        this.baseGraphqlSocketPath = (apiGraphqlSocketUrl || '').replace(/\/+$/, '');
        if (options) {
            this.accountKey = options?.accountKey || this.accountKey;
            this.langKey = options?.langKey || this.langKey;
            this.ssrMode = options?.ssrMode || this.ssrMode;
        }
    }
    async setAccount(id) {
        return Localforage.setItem(this.accountKey, id);
    }
    async getAccount() {
        return Localforage.getItem(this.accountKey);
    }
    async destroyAccount() {
        return Localforage.removeItem(this.accountKey);
    }
    async setLang(lang) {
        return Localforage.setItem(this.langKey, lang || 'en');
    }
    async getLang() {
        return Localforage.getItem(this.langKey);
    }
    async destroyLang() {
        return Localforage.removeItem(this.langKey);
    }
}
