import revive_payload_client_T0BLu7qIP2 from "/builds/bounsel/flow/frontend/app-flow/node_modules/.pnpm/nuxt@3.7.0_@types+node@20.5.1_eslint@8.51.0_rollup@3.29.4_sass@1.57.1_typescript@4.9.5_vue-tsc@1.0.24/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_seN3UXe4Zf from "/builds/bounsel/flow/frontend/app-flow/node_modules/.pnpm/nuxt@3.7.0_@types+node@20.5.1_eslint@8.51.0_rollup@3.29.4_sass@1.57.1_typescript@4.9.5_vue-tsc@1.0.24/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_1tT5hflf3X from "/builds/bounsel/flow/frontend/app-flow/node_modules/.pnpm/nuxt@3.7.0_@types+node@20.5.1_eslint@8.51.0_rollup@3.29.4_sass@1.57.1_typescript@4.9.5_vue-tsc@1.0.24/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_nuEmHbTBGA from "/builds/bounsel/flow/frontend/app-flow/node_modules/.pnpm/@pinia+nuxt@0.4.6_rollup@3.29.4_typescript@4.9.5_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/bounsel/flow/frontend/app-flow/apps/editor/.nuxt/components.plugin.mjs";
import unhead_vtL0RuOI7T from "/builds/bounsel/flow/frontend/app-flow/node_modules/.pnpm/nuxt@3.7.0_@types+node@20.5.1_eslint@8.51.0_rollup@3.29.4_sass@1.57.1_typescript@4.9.5_vue-tsc@1.0.24/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_Puiz07kMmR from "/builds/bounsel/flow/frontend/app-flow/node_modules/.pnpm/nuxt@3.7.0_@types+node@20.5.1_eslint@8.51.0_rollup@3.29.4_sass@1.57.1_typescript@4.9.5_vue-tsc@1.0.24/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_hZaOhaH0li from "/builds/bounsel/flow/frontend/app-flow/packages/@bounsel/bui/src/plugins/primevue.ts";
import eventBus_P2l3PQ4DVK from "/builds/bounsel/flow/frontend/app-flow/packages/@bounsel/shared/src/runtime/plugins/eventBus.ts";
import chunk_reload_client_eR75isqn6G from "/builds/bounsel/flow/frontend/app-flow/node_modules/.pnpm/nuxt@3.7.0_@types+node@20.5.1_eslint@8.51.0_rollup@3.29.4_sass@1.57.1_typescript@4.9.5_vue-tsc@1.0.24/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_SVnwK7EMrr from "/builds/bounsel/flow/frontend/app-flow/apps/editor/plugins/analytics.ts";
import i18n_VfGcjrvSkj from "/builds/bounsel/flow/frontend/app-flow/apps/editor/plugins/i18n.ts";
import primevue_7rYYRZQLyx from "/builds/bounsel/flow/frontend/app-flow/apps/editor/plugins/primevue.ts";
import sdk_R939RdCrQq from "/builds/bounsel/flow/frontend/app-flow/apps/editor/plugins/sdk.ts";
export default [
  revive_payload_client_T0BLu7qIP2,
  router_seN3UXe4Zf,
  payload_client_1tT5hflf3X,
  plugin_vue3_nuEmHbTBGA,
  components_plugin_KR1HBZs4kY,
  unhead_vtL0RuOI7T,
  prefetch_client_Puiz07kMmR,
  primevue_hZaOhaH0li,
  eventBus_P2l3PQ4DVK,
  chunk_reload_client_eR75isqn6G,
  analytics_SVnwK7EMrr,
  i18n_VfGcjrvSkj,
  primevue_7rYYRZQLyx,
  sdk_R939RdCrQq
]