import { initSdk } from '@bounsel/sdk';
import {
  DefaultApolloClient,
  provideApolloClient,
} from '@vue/apollo-composable';

export default defineNuxtPlugin((app) => {
  const configRuntime = useRuntimeConfig();

  const { apolloClient, config } = initSdk({
    apiUrl: configRuntime.public.apiUrl,
    apiGraphqlUrl: configRuntime.public.apiGraphqlUrl,
    apiGraphqlSocketUrl: configRuntime.public.apiGraphqlSocketUrl,
  });

  provideApolloClient(apolloClient);
  app.vueApp.provide(DefaultApolloClient, apolloClient);

  return {
    provide: {
      sdkConfig: config,
    },
  };
});
