import { ApolloClient, split, InMemoryCache, } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { logErrorMessages } from '@vue/apollo-util';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import * as fragments from './generated/graphql/fragment-matcher.json';
import { getCsrfToken } from './utils';
export const createApolloClient = (config) => {
    const customHeaders = async (request) => {
        const account = await config.getAccount();
        // Add CSRF token if graphql is mutation
        let csrfToken = undefined;
        if (request &&
            request?.query?.definitions?.length > 0 &&
            (request?.query?.definitions[0]).operation ===
                'mutation') {
            csrfToken = await getCsrfToken(config.basePath);
        }
        return {
            'Content-Type': 'application/json',
            'Accept-Language': (await config.getLang()) || 'en',
            [config.accountKey]: account || null,
            ...(csrfToken
                ? {
                    'x-csrf-token': csrfToken,
                }
                : {}),
        };
    };
    // HTTP connection to the API
    const httpOptions = {
        uri: config.baseGraphqlPath,
        credentials: 'include',
    };
    const httpLink = createUploadLink(httpOptions);
    // Handle errors
    const errorLink = onError((error) => {
        if (process.env.NODE_ENV === 'development') {
            logErrorMessages(error);
        }
    });
    const authLink = setContext(async (request, { headers }) => {
        return {
            headers: {
                ...headers,
                ...(await customHeaders(request)),
            },
        };
    });
    // Create the subscription websocket link
    let restartRequestedBeforeConnected = false;
    let gracefullyRestart = () => {
        restartRequestedBeforeConnected = true;
    };
    const wsLink = typeof window !== 'undefined'
        ? new GraphQLWsLink(createClient({
            url: config.baseGraphqlSocketPath,
            lazy: true,
            connectionParams: async () => {
                return {
                    ...(await customHeaders()),
                };
            },
            on: {
                connected: (socket) => {
                    gracefullyRestart = () => {
                        if (socket.readyState === WebSocket.OPEN) {
                            socket.close(4205, 'Client Restart');
                        }
                    };
                    // just in case you were eager to restart
                    if (restartRequestedBeforeConnected) {
                        restartRequestedBeforeConnected = false;
                        gracefullyRestart();
                    }
                },
            },
        }))
        : null;
    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = typeof window !== 'undefined' && wsLink != null
        ? split(
        // split based on operation type
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription');
        }, wsLink, httpLink)
        : httpLink;
    const cache = new InMemoryCache({
        possibleTypes: fragments.possibleTypes,
    });
    const apolloClient = new ApolloClient({
        ssrMode: config.ssrMode,
        link: authLink.concat(errorLink).concat(link),
        cache,
        connectToDevTools: process.env.NODE_ENV === 'development' ? true : false,
    });
    apolloClient.defaultOptions.query = {
        fetchPolicy: 'no-cache',
    };
    return apolloClient;
};
export * from './generated/graphql/graphql';
