import { calendarLocales } from './calendarLocales';

export default {};
export const useSetDateLanguage = (primeConfig: any, language: 'es' | 'en') => {
  primeConfig.config.locale.dayNames = calendarLocales.dayNames[language];
  primeConfig.config.locale.dayNamesShort =
    calendarLocales.dayNamesShort[language];
  primeConfig.config.locale.dayNamesMin = calendarLocales.dayNamesMin[language];
  primeConfig.config.locale.monthNames = calendarLocales.monthNames[language];
  primeConfig.config.locale.monthNamesShort =
    calendarLocales.monthNamesShort[language];
  primeConfig.config.locale.chooseYear = calendarLocales.chooseYear[language];
  primeConfig.config.locale.chooseMonth = calendarLocales.chooseMonth[language];
  primeConfig.config.locale.chooseDate = calendarLocales.chooseDate[language];
  primeConfig.config.locale.prevDecade = calendarLocales.prevDecade[language];
  primeConfig.config.locale.nextDecade = calendarLocales.nextDecade[language];
  primeConfig.config.locale.prevYear = calendarLocales.prevYear[language];
  primeConfig.config.locale.nextYear = calendarLocales.nextYear[language];
  primeConfig.config.locale.prevMonth = calendarLocales.prevMonth[language];
  primeConfig.config.locale.nextMonth = calendarLocales.nextMonth[language];
  primeConfig.config.locale.prevHour = calendarLocales.prevHour[language];
  primeConfig.config.locale.nextHour = calendarLocales.nextHour[language];
  primeConfig.config.locale.today = calendarLocales.today[language];
  primeConfig.config.locale.firstDayOfWeek =
    calendarLocales.firstDayOfWeek[language];
  primeConfig.config.locale.clear = calendarLocales.clear[language];
};
