import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';

export default defineNuxtPlugin((app) => {
  app.vueApp.use(PrimeVue, { ripple: true });
  app.vueApp.directive('tooltip', Tooltip);
  app.vueApp.use(DialogService);
  app.vueApp.use(ToastService);
});
