import Posthog from 'posthog-js';

export default defineNuxtPlugin(() => {
  let analyticsLoaded = false;
  const config = useRuntimeConfig();
  const posthogClient = Posthog.init(config.public.posthogPublicKey, {
    api_host: config.public.posthogHost || 'https://app.posthog.com',
    loaded: (posthog) => {
      analyticsLoaded = true;
      if (import.meta.env.MODE === 'development') posthog.debug();
    },
    autocapture: false,
    enable_recording_console_log: true,
    persistence: 'localStorage+cookie',
  }) as typeof Posthog;

  const router = useRouter();
  router.afterEach((to) => {
    posthogClient.capture('$pageview', {
      current_url: to.fullPath,
    });
  });

  const analytics = {
    isLoaded() {
      return analyticsLoaded;
    },
    identify(user: {
      id: string;
      email?: string;
      name?: string;
      language?: string;
      avatar?: string;
      tos?: boolean;
      timezone?: string;
    }) {
      if (config.public.posthogPublicKey) {
        posthogClient.identify(user.id, {
          email: user?.email,
          name: user?.name,
          language: user?.language,
          tos: user?.tos,
          timezone: user?.timezone,
        });
        posthogClient.reloadFeatureFlags();
      }
    },
    reset() {
      if (config.public.posthogPublicKey) {
        posthogClient.reset();
        posthogClient.reloadFeatureFlags();
      }
    },
    capture(eventName: string, props: { [key: string]: never }) {
      if (config.public.posthogPublicKey) {
        return posthogClient.capture(eventName, props);
      }
    },
    pageView(props?: { [key: string]: never }) {
      try {
        if (config.public.posthogPublicKey) {
          return posthogClient.capture('$pageview', props);
        }
      } catch (error) {
        /* empty */
      }
    },
    isCapabilityEnabled(key: string) {
      if (config.public.posthogPublicKey) {
        return posthogClient.isFeatureEnabled(key, { send_event: true });
      }

      return true;
    },
  };

  return {
    provide: {
      analytics,
    },
  };
});
