/* tslint:disable */
/* eslint-disable */
/**
 * engine
 *        👋 Welcome to the API documentation! This documentation provides a detailed overview of all the endpoints available in our API, along with their request and response formats, authentication requirements, and other important details.        Each endpoint is accompanied by a set of examples that demonstrate how to use it in various scenarios. These examples include sample requests and responses, as well as information on any error codes or messages that may be returned.        Note that some endpoints may require authentication or special permissions to access. If you\'re not sure how to authenticate with our API or what permissions are required for a particular endpoint, please refer to the authentication section of this documentation.        We hope you find this documentation helpful, and if you have any questions or feedback, please don\'t hesitate to contact us.
 *
 * The version of the OpenAPI document: 1.17.23
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import globalAxios from 'axios';
export const BASE_PATH = 'http://localhost:3000'.replace(/\/+$/, '');
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|',
};
/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    basePath;
    axios;
    configuration;
    constructor(configuration, basePath = BASE_PATH, axios = globalAxios) {
        this.basePath = basePath;
        this.axios = axios;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    field;
    constructor(field, msg) {
        super(msg);
        this.field = field;
        this.name = 'RequiredError';
    }
}
