export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","crossorigin":"anonymous","href":"https://fonts.gstatic.com"},{"rel":"preload","as":"style","href":"https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"}],"style":[{"children":".font-opensans{font-family:\"OpenSans\";}"}],"script":[],"noscript":[]}

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'