import { default as edits4kTQm5UMlMeta } from "/builds/bounsel/flow/frontend/app-flow/apps/editor/pages/d/[id]/edit.vue?macro=true";
import { default as loginBCnMZtmH3aMeta } from "/builds/bounsel/flow/frontend/app-flow/apps/editor/pages/login.vue?macro=true";
export default [
  {
    name: edits4kTQm5UMlMeta?.name ?? "d-id-edit",
    path: edits4kTQm5UMlMeta?.path ?? "/d/:id()/edit",
    meta: edits4kTQm5UMlMeta || {},
    alias: edits4kTQm5UMlMeta?.alias || [],
    redirect: edits4kTQm5UMlMeta?.redirect || undefined,
    component: () => import("/builds/bounsel/flow/frontend/app-flow/apps/editor/pages/d/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: loginBCnMZtmH3aMeta?.name ?? "login",
    path: loginBCnMZtmH3aMeta?.path ?? "/login",
    meta: loginBCnMZtmH3aMeta || {},
    alias: loginBCnMZtmH3aMeta?.alias || [],
    redirect: loginBCnMZtmH3aMeta?.redirect || undefined,
    component: () => import("/builds/bounsel/flow/frontend/app-flow/apps/editor/pages/login.vue").then(m => m.default || m)
  }
]