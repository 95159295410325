import { ACCOUNT } from '../config';
import { getCsrfToken } from './csrf';
export const requestInterceptors = (config) => async (requestConfig) => {
    requestConfig.headers = requestConfig.headers || {};
    requestConfig.headers['Content-Type'] =
        'application/json';
    await config.getAccount().then((account) => {
        if (account) {
            requestConfig.headers[ACCOUNT] = account;
        }
        else {
            delete requestConfig.headers[ACCOUNT];
        }
    });
    if (['post', 'put', 'patch', 'delete'].includes(requestConfig.method || '')) {
        const csrfToken = await getCsrfToken(config.basePath);
        if (csrfToken) {
            requestConfig.headers['x-csrf-token'] = csrfToken;
        }
    }
    return requestConfig;
};
