import { createI18n } from 'vue-i18n';
import type { Locale } from 'vue-i18n';
import { useNavigatorLanguage } from '@vueuse/core';
import { LANGUAGES } from '../i18n/languages';
import { numberFormats } from '../i18n/numberFormats';
import messages from '@intlify/unplugin-vue-i18n/messages';

const { language } = useNavigatorLanguage();

function getDefautlLocale(lang: string): Locale {
  const [language, region] = lang.split('-');
  const fullLocale = `${language}${
    region ? '-' + region : ''
  }`.toLocaleLowerCase();
  let result = 'en';

  if (LANGUAGES.includes(fullLocale)) {
    result = fullLocale;
  } else if (LANGUAGES.includes(language)) {
    result = language.toLocaleLowerCase();
  }

  return result;
}

export default defineNuxtPlugin((app) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    lazy: true,
    locale: getDefautlLocale(language.value as string),
    fallbackLocale: 'en',
    messages,
    numberFormats,
    vueI18nLoader: true,
    // strategy: 'no_prefix',
    detectBrowserLanguage: {
      useCookie: true,
      cookieKey: '__i18n__',
    },
  });
  app.vueApp.use(i18n);
});
