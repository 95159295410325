export const responseInterceptors = async (response) => {
    return response;
};
export const responseErrorInterceptors = (callbacks) => async (error) => {
    const status = error.response?.status;
    if (status && callbacks[status]) {
        await callbacks[status](error);
    }
    return Promise.reject(error);
};
