export const numberFormats: { [x: string]: any } = {
  en: {
    currency: {
      style: 'currency',
      currency: 'usd',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  es: {
    currency: {
      style: 'currency',
      currency: 'eur',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  ca: {
    currency: {
      style: 'currency',
      currency: 'eur',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  pt: {
    currency: {
      style: 'currency',
      currency: 'eur',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-br': {
    currency: {
      style: 'currency',
      currency: 'brl',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  // 'ja-JP': {
  //   currency: {
  //     style: 'currency',
  //     currency: 'JPY',
  //     useGrouping: true,
  //     currencyDisplay: 'symbol',
  //   },
  //   decimal: {
  //     style: 'decimal',
  //     minimumSignificantDigits: 3,
  //     maximumSignificantDigits: 5,
  //   },
  //   percent: {
  //     style: 'percent',
  //     useGrouping: false,
  //   },
  // },
};
