/* tslint:disable */
/* eslint-disable */
/**
 * engine
 *        👋 Welcome to the API documentation! This documentation provides a detailed overview of all the endpoints available in our API, along with their request and response formats, authentication requirements, and other important details.        Each endpoint is accompanied by a set of examples that demonstrate how to use it in various scenarios. These examples include sample requests and responses, as well as information on any error codes or messages that may be returned.        Note that some endpoints may require authentication or special permissions to access. If you\'re not sure how to authenticate with our API or what permissions are required for a particular endpoint, please refer to the authentication section of this documentation.        We hope you find this documentation helpful, and if you have any questions or feedback, please don\'t hesitate to contact us.
 *
 * The version of the OpenAPI document: 1.17.23
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequiredError } from './base';
/**
 *
 * @export
 */
export const DUMMY_BASE_URL = 'https://example.com';
/**
 *
 * @throws {RequiredError}
 * @export
 */
export const assertParamExists = function (functionName, paramName, paramValue) {
    if (paramValue === null || paramValue === undefined) {
        throw new RequiredError(paramName, `Required parameter ${paramName} was null or undefined when calling ${functionName}.`);
    }
};
/**
 *
 * @export
 */
export const setApiKeyToObject = async function (object, keyParamName, configuration) {
    if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function'
            ? await configuration.apiKey(keyParamName)
            : await configuration.apiKey;
        object[keyParamName] = localVarApiKeyValue;
    }
};
/**
 *
 * @export
 */
export const setBasicAuthToObject = function (object, configuration) {
    if (configuration && (configuration.username || configuration.password)) {
        object['auth'] = {
            username: configuration.username,
            password: configuration.password,
        };
    }
};
/**
 *
 * @export
 */
export const setBearerAuthToObject = async function (object, configuration) {
    if (configuration && configuration.accessToken) {
        const accessToken = typeof configuration.accessToken === 'function'
            ? await configuration.accessToken()
            : await configuration.accessToken;
        object['Authorization'] = 'Bearer ' + accessToken;
    }
};
/**
 *
 * @export
 */
export const setOAuthToObject = async function (object, name, scopes, configuration) {
    if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
            ? await configuration.accessToken(name, scopes)
            : await configuration.accessToken;
        object['Authorization'] = 'Bearer ' + localVarAccessTokenValue;
    }
};
function setFlattenedQueryParams(urlSearchParams, parameter, key = '') {
    if (parameter == null)
        return;
    if (typeof parameter === 'object') {
        if (Array.isArray(parameter)) {
            parameter.forEach((item) => setFlattenedQueryParams(urlSearchParams, item, key));
        }
        else {
            Object.keys(parameter).forEach((currentKey) => setFlattenedQueryParams(urlSearchParams, parameter[currentKey], `${key}${key !== '' ? '.' : ''}${currentKey}`));
        }
    }
    else {
        if (urlSearchParams.has(key)) {
            urlSearchParams.append(key, parameter);
        }
        else {
            urlSearchParams.set(key, parameter);
        }
    }
}
/**
 *
 * @export
 */
export const setSearchParams = function (url, ...objects) {
    const searchParams = new URLSearchParams(url.search);
    setFlattenedQueryParams(searchParams, objects);
    url.search = searchParams.toString();
};
/**
 *
 * @export
 */
export const serializeDataIfNeeded = function (value, requestOptions, configuration) {
    const nonString = typeof value !== 'string';
    const needsSerialization = nonString && configuration && configuration.isJsonMime
        ? configuration.isJsonMime(requestOptions.headers['Content-Type'])
        : nonString;
    return needsSerialization
        ? JSON.stringify(value !== undefined ? value : {})
        : value || '';
};
/**
 *
 * @export
 */
export const toPathString = function (url) {
    return url.pathname + url.search + url.hash;
};
/**
 *
 * @export
 */
export const createRequestFunction = function (axiosArgs, globalAxios, BASE_PATH, configuration) {
    return (axios = globalAxios, basePath = BASE_PATH) => {
        const axiosRequestArgs = {
            ...axiosArgs.options,
            url: (configuration?.basePath || basePath) + axiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
    };
};
